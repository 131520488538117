@font-face {
	font-family: "kfont";
	src: url('../fonts/kfont.eot');
	src: url('../fonts/kfont.eot?#iefix') format('eot'),
	url('../fonts/kfont.woff2') format('woff2'),
	url('../fonts/kfont.woff') format('woff'),
	url('../fonts/kfont.ttf') format('truetype'),
	url('../fonts/kfont.svg#kfont') format('svg');
	font-weight: normal;
    font-style: normal;
    font-display: block;
}

body .icon-facebook:before,
body .icon-instagram:before,
body .icon-linkedin:before,
body .icon-pinterest:before,
body .icon-twitter:before,
body .icon-youtube:before,
body .icon-caret-left:before,
body .icon-caret-right:before,
body .icon-caret-top:before,
body .icon-history:before,
body .icon-information:before,
body .icon-cart:before,
body .icon-wishlist:before,
body .icon-caret-bottom:before,
body .icon-user:before,
body .icon-eye:before,
body .icon-heart:before,
body .icon-phone:before,
body .icon-address:before,
body .icon-voucher:before,
body .icon-schedule:before,
body .icon-delete:before,
body .icon-new-address:before,
body .icon-main-address:before,
body .icon-grid:before,
body .icon-table:before,
body .icon-moins:before,
body .icon-plus:before,
body .icon-hours:before,
body .icon-email:before,
body .icon-mapmarker:before,
body .icon-arrow-down:before,
body .icon-arrow-left:before,
body .icon-arrow-right:before,
body .icon-arrow-top:before,
body .icon-calendar:before,
body .icon-check:before,
body .icon-danger:before,
body .icon-favorite-full:before,
body .icon-folder:before,
body .icon-home:before,
body .icon-map:before,
body .icon-modify:before,
body .icon-parameters:before,
body .icon-upload:before,
body .icon-filtre:before,
body .icon-hide:before,
body .icon-show:before,
body .icon-gsn-calendar:before,
body .icon-gsn-bordure-image:before,
body .icon-gsn-ordinateur:before,
body .icon-gsn-mail:before,
body .icon-gsn-marque_page:before,
body .icon-gsn-menu:before,
body .icon-gsn-ec-favoris:before,
body .icon-gsn-favoris-full:before,
body .icon-gsn-marque-page-full:before,
body .icon-gsn-ec-commandes:before,
body .icon-gsn-presentation:before,
body .icon-gsn-souligne_l:before,
body .icon-gsn-souligne_m:before,
body .icon-gsn-souligne_s:before,
body .icon-gsn-souligne_xl:before,
body .icon-gsn-souligne_xs:before,
body .icon-gsn-cadenas-ferme-plus:before,
body .icon-gsn-recherche:before,
body .icon-cross:before,
body .icon-gsn-ec-accueil:before,
body .icon-gsn-ressources_supp:before,
body .icon-gsn-compagnon:before,
body .icon-gsn-ec-contacts:before,
body .icon-gsn-contacts:before,
body .icon-gsn-citation:before,
body .icon-gsn-mapmarker:before,
body .icon-gsn-prescripteur:before,
body .icon-gsn-cadenas-ferme:before,
body .icon-gsn-accueil:before,
body .icon-gsn-actu:before,
body .icon-gsn-avion:before,
body .icon-gsn-cadenas:before,
body .icon-gsn-check:before,
body .icon-gsn-chevron:before,
body .icon-gsn-commandes:before,
body .icon-gsn-compte:before,
body .icon-gsn-contact:before,
body .icon-gsn-deconnexion:before,
body .icon-gsn-ecolier:before,
body .icon-gsn-externe:before,
body .icon-gsn-facebook:before,
body .icon-gsn-favoris:before,
body .icon-gsn-fermer:before,
body .icon-gsn-filtres:before,
body .icon-gsn-fleche:before,
body .icon-gsn-graphiti_big:before,
body .icon-gsn-graphiti_serre:before,
body .icon-gsn-graphiti_small:before,
body .icon-gsn-info:before,
body .icon-gsn-ec-compte:before,
body .icon-gsn-instagram:before,
body .icon-gsn-linkedin:before,
body .icon-gsn-livre:before,
body .icon-hide2:before,
body .icon-gsn-ec-prescripteur:before,
body .icon-gsn-caddie:before,
body .icon-gsn-numerique:before,
body .icon-gsn-panier:before,
body .icon-gsn-partage:before,
body .icon-gsn-pause:before,
body .icon-gsn-play:before,
body .icon-gsn-plus:before,
body .icon-gsn-portefeuille:before,
body .icon-gsn-poubelle:before,
body .icon-gsn-qrcode:before,
body .icon-gsn-ressources:before,
body .icon-gsn-retourner:before,
body .icon-gsn-son:before,
body .icon-gsn-souligne:before,
body .icon-gsn-specimen:before,
body .icon-gsn-surligne:before,
body .icon-gsn-telecharger:before,
body .icon-gsn-telephone:before,
body .icon-gsn-tiktok:before,
body .icon-gsn-trombone:before,
body .icon-gsn-x:before,
body .icon-gsn-youtube:before,
body .icon-gsn-extrait:before,
body .icon-gsn-specimen2:before,
body .icon-gsn-smartphone:before,
body .icon-gsn-livre_marque_page:before,
body .icon-gsn-cadenas-ouvert:before,
body .icon-gsn-rc:before {
  font-family: "kfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

body .icon-facebook:before { content: "\EA01" }
body .icon-instagram:before { content: "\EA02" }
body .icon-linkedin:before { content: "\EA03" }
body .icon-pinterest:before { content: "\EA04" }
body .icon-twitter:before { content: "\EA05" }
body .icon-youtube:before { content: "\EA06" }
body .icon-caret-left:before { content: "\EA07" }
body .icon-caret-right:before { content: "\EA08" }
body .icon-caret-top:before { content: "\EA09" }
body .icon-history:before { content: "\EA0E" }
body .icon-information:before { content: "\EA0F" }
body .icon-cart:before { content: "\EA10" }
body .icon-wishlist:before { content: "\EA11" }
body .icon-caret-bottom:before { content: "\EA12" }
body .icon-user:before { content: "\EA13" }
body .icon-eye:before { content: "\EA14" }
body .icon-heart:before { content: "\EA15" }
body .icon-phone:before { content: "\EA16" }
body .icon-address:before { content: "\EA17" }
body .icon-voucher:before { content: "\EA18" }
body .icon-schedule:before { content: "\EA19" }
body .icon-delete:before { content: "\EA1A" }
body .icon-new-address:before { content: "\EA1B" }
body .icon-main-address:before { content: "\EA1C" }
body .icon-grid:before { content: "\EA1D" }
body .icon-table:before { content: "\EA1E" }
body .icon-moins:before { content: "\EA1F" }
body .icon-plus:before { content: "\EA20" }
body .icon-hours:before { content: "\EA21" }
body .icon-email:before { content: "\EA22" }
body .icon-mapmarker:before { content: "\EA23" }
body .icon-arrow-down:before { content: "\EA24" }
body .icon-arrow-left:before { content: "\EA25" }
body .icon-arrow-right:before { content: "\EA26" }
body .icon-arrow-top:before { content: "\EA27" }
body .icon-calendar:before { content: "\EA28" }
body .icon-check:before { content: "\EA29" }
body .icon-danger:before { content: "\EA2A" }
body .icon-favorite-full:before { content: "\EA2B" }
body .icon-folder:before { content: "\EA2C" }
body .icon-home:before { content: "\EA2D" }
body .icon-map:before { content: "\EA2E" }
body .icon-modify:before { content: "\EA2F" }
body .icon-parameters:before { content: "\EA30" }
body .icon-upload:before { content: "\EA31" }
body .icon-filtre:before { content: "\EA32" }
body .icon-hide:before { content: "\EA33" }
body .icon-show:before { content: "\EA34" }
body .icon-gsn-calendar:before { content: "\EA75" }
body .icon-gsn-bordure-image:before { content: "\ECE4" }
body .icon-gsn-ordinateur:before { content: "\ECEF" }
body .icon-gsn-mail:before { content: "\EDA2" }
body .icon-gsn-marque_page:before { content: "\EDA3" }
body .icon-gsn-menu:before { content: "\EDA4" }
body .icon-gsn-ec-favoris:before { content: "\EDEE" }
body .icon-gsn-favoris-full:before { content: "\EF58" }
body .icon-gsn-marque-page-full:before { content: "\EF59" }
body .icon-gsn-ec-commandes:before { content: "\F0A4" }
body .icon-gsn-presentation:before { content: "\F0CA" }
body .icon-gsn-souligne_l:before { content: "\F111" }
body .icon-gsn-souligne_m:before { content: "\F112" }
body .icon-gsn-souligne_s:before { content: "\F113" }
body .icon-gsn-souligne_xl:before { content: "\F114" }
body .icon-gsn-souligne_xs:before { content: "\F115" }
body .icon-gsn-cadenas-ferme-plus:before { content: "\F132" }
body .icon-gsn-recherche:before { content: "\F2C2" }
body .icon-cross:before { content: "\F373" }
body .icon-gsn-ec-accueil:before { content: "\F40E" }
body .icon-gsn-ressources_supp:before { content: "\F41D" }
body .icon-gsn-compagnon:before { content: "\F563" }
body .icon-gsn-ec-contacts:before { content: "\F5D8" }
body .icon-gsn-contacts:before { content: "\F685" }
body .icon-gsn-citation:before { content: "\F7F7" }
body .icon-gsn-mapmarker:before { content: "\F84F" }
body .icon-gsn-prescripteur:before { content: "\F8A1" }
body .icon-gsn-cadenas-ferme:before { content: "\FA02" }
body .icon-gsn-accueil:before { content: "\FA27" }
body .icon-gsn-actu:before { content: "\FA28" }
body .icon-gsn-avion:before { content: "\FA29" }
body .icon-gsn-cadenas:before { content: "\FA2A" }
body .icon-gsn-check:before { content: "\FA2B" }
body .icon-gsn-chevron:before { content: "\FA2C" }
body .icon-gsn-commandes:before { content: "\FA2D" }
body .icon-gsn-compte:before { content: "\FA2E" }
body .icon-gsn-contact:before { content: "\FA2F" }
body .icon-gsn-deconnexion:before { content: "\FA30" }
body .icon-gsn-ecolier:before { content: "\FA31" }
body .icon-gsn-externe:before { content: "\FA32" }
body .icon-gsn-facebook:before { content: "\FA33" }
body .icon-gsn-favoris:before { content: "\FA34" }
body .icon-gsn-fermer:before { content: "\FA35" }
body .icon-gsn-filtres:before { content: "\FA36" }
body .icon-gsn-fleche:before { content: "\FA37" }
body .icon-gsn-graphiti_big:before { content: "\FA38" }
body .icon-gsn-graphiti_serre:before { content: "\FA39" }
body .icon-gsn-graphiti_small:before { content: "\FA3A" }
body .icon-gsn-info:before { content: "\FA3B" }
body .icon-gsn-ec-compte:before { content: "\FA3C" }
body .icon-gsn-instagram:before { content: "\FA3D" }
body .icon-gsn-linkedin:before { content: "\FA3E" }
body .icon-gsn-livre:before { content: "\FA3F" }
body .icon-hide2:before { content: "\FB20" }
body .icon-gsn-ec-prescripteur:before { content: "\FBB5" }
body .icon-gsn-caddie:before { content: "\FC02" }
body .icon-gsn-numerique:before { content: "\FC31" }
body .icon-gsn-panier:before { content: "\FC33" }
body .icon-gsn-partage:before { content: "\FC34" }
body .icon-gsn-pause:before { content: "\FC35" }
body .icon-gsn-play:before { content: "\FC36" }
body .icon-gsn-plus:before { content: "\FC37" }
body .icon-gsn-portefeuille:before { content: "\FC38" }
body .icon-gsn-poubelle:before { content: "\FC39" }
body .icon-gsn-qrcode:before { content: "\FC3A" }
body .icon-gsn-ressources:before { content: "\FC3C" }
body .icon-gsn-retourner:before { content: "\FC3E" }
body .icon-gsn-son:before { content: "\FC3F" }
body .icon-gsn-souligne:before { content: "\FC40" }
body .icon-gsn-specimen:before { content: "\FC41" }
body .icon-gsn-surligne:before { content: "\FC42" }
body .icon-gsn-telecharger:before { content: "\FC43" }
body .icon-gsn-telephone:before { content: "\FC44" }
body .icon-gsn-tiktok:before { content: "\FC45" }
body .icon-gsn-trombone:before { content: "\FC46" }
body .icon-gsn-x:before { content: "\FC47" }
body .icon-gsn-youtube:before { content: "\FC48" }
body .icon-gsn-extrait:before { content: "\FCAE" }
body .icon-gsn-specimen2:before { content: "\FCAF" }
body .icon-gsn-smartphone:before { content: "\FCF6" }
body .icon-gsn-livre_marque_page:before { content: "\FD71" }
body .icon-gsn-cadenas-ouvert:before { content: "\FDB7" }
body .icon-gsn-rc:before { content: "\FEAA" }
